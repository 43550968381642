export default (theme) => ({
  input: {
    fontSize: 14,
    border: "solid 1px rgba(155,155,155,0.1)",
    borderRadius: 2,
    transition: "border .1s",
    "&:hover": {
      border: "solid 1px #2196f3",
    },
  },
});
