import placeEvents from './placeEvents';
import promoCodes from './promoCodes';
import places from './places';
import { combineReducers } from "redux"; //eslint-disable-line
import app from "./app";
import user from "./user";
export default combineReducers({
  user,
  app,
  places,
  promoCodes,
  placeEvents,
});
