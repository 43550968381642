export function parsePlace(p) {
  const place = {
    place_id: null,
    verified: false,
    latitude: null,
    longitude: null,
    partial_match: false,
  };

  if (p !== null) {
    place.place_id = p.place_id;
    place.name = p.name;
    if (p.geometry !== null) {
      place.location = p.geometry.location;
      if (p.geometry.location !== null) {
        place.latitude = place.location.lat();
        place.longitude = place.location.lng();
      }
    }
    place.formatted_address = p.formatted_address;
    const _ref = p.address_components;
    const _len = _ref.length;
    for (let _i = 0; _i < _len; _i++) {
      const i = _ref[_i];
      const _ref1 = i.types;
      const _len1 = _ref1.length;
      for (let _j = 0; _j < _len1; _j++) {
        const t = _ref1[_j];
        place[t] = i.short_name;
        place[`${t}_long`] = i.long_name;
      }
    }
    place.line_1 = `${place.street_number} ${place.route}`.trim();
    place.line_2 = place.subpremise;
    place.city =
      place.locality_long ||
      place.sublocality_long ||
      place.sublocality_level_1_long ||
      place.neighborhood_long;
    place.state = place.administrative_area_level_1;
    place.zip = place.postal_code;
    place.types = p.types;
    place.verified = place.street_number !== null;
  }

  return {
    addressId: place.address_id,
    formattedAddress: place.formatted_address,
    line1: place.line_1,
    line2: place.line_2 || "",
    city: place.city,
    state: place.state,
    zip: place.zip,
    country: place.country,
    latitude: `${place.latitude}`,
    longitude: `${place.longitude}`,
    timezone: place.timezone,
    placeId: place.place_id,
    verified: place.verified,
  };
}
