// import PropTypes from 'prop-types';
// material-ui
import {
  Checkbox,
  Chip,
  Divider,
  Grid,
  Icon,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Tabs,
  Tooltip,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { DeleteForever, MoreVert } from "@material-ui/icons";
import { Rating } from "@material-ui/lab";
import {
  createCampaign,
  createPlaceEvent,
  deletePlace,
  deletePlaceEvent,
  setPlace,
  updateCampaign,
  updatePlace,
  updatePlaceEvent,
} from "actions";
import { deleteCampaign } from "actions/campaigns";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function PlaceDetails({ place, footer }) {
  const [tabIndex, setTabIndex] = React.useState(0);
  const classes = useStyles(styles);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState();

  const onChange = (key) => (e) => {
    dispatch(setPlace({ ...place, [key]: e.target.value }));
  };

  const onDeletePlace = () => {
    dispatch(deletePlace(place.id));
  };

  const campaignContent = () => {
    return (
      <div style={{ marginTop: 8 }}>
        <Chip
          size="small"
          onClick={() =>
            dispatch(
              createCampaign({
                placeID: place.id,
              })
            )
          }
          label="+ Campaign"
        />
        {place.campaigns?.reverse().map((c, i) => (
          <div style={{ marginTop: 8 }}>
            <Campaign
              key={c.id}
              campaign={c}
              onChange={(_c) => {
                const _campaigns = [...place.campaigns];
                _campaigns[i] = _c;
                onChange("campaigns")({ target: { value: _campaigns } });
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  const eventContent = () => {
    return (
      <div style={{ marginTop: 8 }}>
        <Chip
          size="small"
          onClick={() =>
            dispatch(
              createPlaceEvent({
                placeID: place.id,
              })
            )
          }
          label="+ Event"
        />
        {place.events?.reverse().map((p, i) => (
          <div style={{ marginTop: 8 }}>
            <PlaceEvent
              key={p.id}
              placeEvent={p}
              onChange={(_p) => {
                const _events = [...place.events];
                _events[i] = _p;
                onChange("events")({ target: { value: _events } });
              }}
            />
          </div>
        ))}
      </div>
    );
  };

  const getTabContent = () => {
    switch (tabIndex) {
      case 0:
        return campaignContent();
      case 1:
        return eventContent();
      default:
        return <div>Unkown tab</div>;
    }
  };

  const handleShouldSave = () => {
    const _place = { ...place };
    delete _place["events"];
    delete _place["campaign"];
    dispatch(updatePlace(place.id, _place));
  };

  return (
    <div className={classes.container} style={{ position: "relative" }}>
      <div
        style={{
          height: 250,
          width: "100%",
          backgroundColor: "#bbdefb",
          backgroundImage: `url(${place.photo})`,
          backgroundSize: `cover`,
          backgroundPosition: "center center",
          position: "relative",
        }}
      >
        <IconButton
          onClick={(e) => setAnchorEl(e.currentTarget)}
          size="small"
          style={{
            background: "white",
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <MoreVert />
        </IconButton>
        <Menu
          onClose={() => setAnchorEl()}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
        >
          <MenuItem onClick={onDeletePlace}>Delete</MenuItem>
        </Menu>
      </div>
      <div style={{ padding: 16 }}>
        <Typography variant="h6">{place.title}</Typography>
        <Typography variant="body2">
          {place.address}, {place.zipcode} {place.city} {place.state}
        </Typography>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Rating readOnly value={place.rating} size="small" precision={0.1} />
          <Typography variant="body2"> {place.rating}</Typography>
        </div>
        <Typography color="textSecondary" variant="caption">
          #{place.id}, {place.googlePlaceID}
        </Typography>
        <Divider />
        <br />
        <Item
          onChange={"website"}
          editable
          shouldSave={handleShouldSave}
          tooltip={"Website"}
          icon={<Icon class="fa-solid fa-globe" />}
          value={place.website}
          link
        />
        <Item
          editable
          tooltip={"Phone"}
          icon={<Icon class="fa-solid fa-phone" />}
          shouldSave={handleShouldSave}
          value={place.phone}
          onChange={onChange("phone")}
        />
        <Item
          icon={<Icon class="fa-solid fa-address-book" />}
          shouldSave={handleShouldSave}
          value={place.contact}
          tooltip={"Contact"}
          onChange={onChange("contact")}
          editable
          link
        />
        <Item
          icon={<Icon class="fa-solid fa-at" />}
          shouldSave={handleShouldSave}
          value={place.email}
          tooltip={"Email"}
          onChange={onChange("email")}
          editable
          link
        />
        <Tabs
          value={tabIndex}
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, v) => setTabIndex(v)}
          aria-label="disabled tabs example"
        >
          <Tab label="Campaigns" />
          <Tab label="Events" />
        </Tabs>
        {getTabContent()}
      </div>
    </div>
  );
}

const PlaceEvent = ({ placeEvent, onChange }) => {
  const dispatch = useDispatch();

  const handleOnChange = (key, number) => (e) => {
    onChange({
      ...placeEvent,
      [key]: number ? parseInt(e.target.value) : e.target.value,
    });
  };

  const handleShouldSave = () => {
    dispatch(updatePlaceEvent(placeEvent.id, placeEvent));
  };

  return (
    <Paper elevation={4} style={{ padding: 8 }}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="caption">
            {moment(placeEvent.createdAt).fromNow()}
          </Typography>
        </Grid>
        <Grid>
          <Tooltip title="Delete Campgain">
            <DeleteForever
              style={{ cursor: "pointer" }}
              onClick={() => dispatch(deletePlaceEvent(placeEvent.id))}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Item
        value={placeEvent.description}
        tooltip={"description"}
        onChange={handleOnChange("description")}
        editable
        shouldSave={handleShouldSave}
      />
    </Paper>
  );
};

const Campaign = ({ campaign, onChange }) => {
  const dispatch = useDispatch();

  const handleOnChange = (key, number, shouldSave) => (e) => {
    console.log(e);
    const _campaign = {
      ...campaign,
      [key]: number ? parseInt(e.target.value) : e.target.value,
    };
    onChange(_campaign);

    if (shouldSave) {
      dispatch(updateCampaign(campaign.id, _campaign));
    }
  };

  const handleShouldSave = () => {
    dispatch(updateCampaign(campaign.id, campaign));
  };

  return (
    <Paper elevation={4} style={{ padding: 8 }}>
      <Grid container justifyContent="space-between">
        <Grid item>
          <Typography variant="caption">
            {moment(campaign.createdAt).fromNow()}
          </Typography>
        </Grid>
        <Grid>
          <Tooltip title="Delete Campgain">
            <DeleteForever
              style={{ cursor: "pointer" }}
              onClick={() => dispatch(deleteCampaign(campaign.id))}
            />
          </Tooltip>
        </Grid>
      </Grid>
      <Item
        icon={<Icon class="fa-solid fa-qrcode" />}
        tooltip={"Stickered"}
        onChange={handleOnChange("stickered", false, true)}
        value={campaign.stickered}
        editable
        checkbox
      />
      <Item
        icon={<Icon class="fa-solid fa-paper-plane" />}
        value={campaign.contactedTime}
        tooltip={"Contact Count"}
        onChange={handleOnChange("contactedTime", true)}
        editable
        shouldSave={handleShouldSave}
        link
      />
      <Item
        icon={<Icon class="fa-solid fa-gauge-simple" />}
        value={campaign.promoQuantity}
        tooltip={"Redeem Count"}
        onChange={handleOnChange("redeemCount", true)}
        editable
        shouldSave={handleShouldSave}
        link
      />
      <Item
        icon={<Icon class="fa-solid fa-gauge-simple-max" />}
        value={campaign.redeemCount}
        tooltip={"Promo Quantity"}
        onChange={handleOnChange("promoQuantity", true)}
        editable
        shouldSave={handleShouldSave}
        link
      />
      <Item
        icon={<Icon class="fa-solid fa-thumbs-down" />}
        tooltip={"Unsubscribed"}
        onChange={handleOnChange("unsubscribed", false, true)}
        editable
        value={campaign.unsubscribed}
        checkbox
      />
    </Paper>
  );
};

const Item = ({
  icon,
  value,
  label,
  link,
  editable,
  checkbox,
  onChange,
  tooltip,
  shouldSave,
}) => {
  const classes = useStyles(styles);

  return (
    <Tooltip title={tooltip ?? ""}>
      <Grid container alignItems="center" spacing={2}>
        {icon ? (
          <Grid item style={{ width: 50, color: "#2196f3" }}>
            {icon}
          </Grid>
        ) : (
          []
        )}
        <Grid item style={{ flexGrow: 1 }}>
          {editable && !checkbox ? (
            <InputBase
              fullWidth
              onBlur={shouldSave}
              className={classes.input}
              defaultValue={value}
              onChange={onChange}
            />
          ) : (
            <Typography variant="body2">
              {link ? (
                <a target="_blank" href={value}>
                  {value}
                </a>
              ) : (
                label
              )}
            </Typography>
          )}
        </Grid>
        {checkbox ? (
          <Checkbox
            defaultChecked={value}
            onChange={(e) => {
              onChange({ target: { value: e.target.checked } });
            }}
          />
        ) : (
          []
        )}
      </Grid>
    </Tooltip>
  );
};
