export default (theme) => {
  return {
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flex: 1,
    },
    markerDrawer: {
      position: "absolute",
      top: 60,
      left: 10,
      width: 340,
      background: "white",
      borderRadius: 4,
      height: "calc(100vh - 100px)",
      overflow: "scroll",
      boxShadow: "0px 6px 10px rgba(0,0,0,0.2)",
    },
  };
};
