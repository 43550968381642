import {
  GET_PLACES,
  GET_PLACE_BY_ID,
  SET_PLACE,
  SET_PLACES,
} from "constants/places";

export default (state = [], action) => {
  var index;
  switch (action.type) {
    case SET_PLACE:
      const _s1 = [...state];
      index = state.findIndex((e) => e.id === action.place.id);
      _s1[index] = action.place;
      return _s1;
    case SET_PLACES:
      return action.places;
    case `${GET_PLACES}_SUCCESS`:
      return action.payload;
    case `${GET_PLACE_BY_ID}_SUCCESS`:
      const place = { ...action.payload };
      const _s2 = [...state];
      index = state.findIndex((e) => e.id === place.id);
      _s2[index] = place;
      return _s2;
    default:
      return state;
  }
};
