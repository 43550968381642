// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function Marker(options) {
  const [marker, setMarker] = React.useState();

  React.useEffect(() => {
    if (marker) {
      ["click", "idle"].forEach((eventName) =>
        window.google.maps.event.clearListeners(marker, eventName)
      );
      if (options.onClick) {
        marker.addListener("click", options.onClick);
      }
    }
  }, [marker, options]);

  React.useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker());
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);
  React.useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);
  return null;
}
