import {
  GET_PROMO_CODES,
} from 'constants/promoCodes';

export default (state = [], action) => {
    switch (action.type) {
    case `${GET_PROMO_CODES}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
