import * as Api from "api";
import {
  CREATE_PLACE,
  DELETE_PLACE,
  GET_PLACES,
  GET_PLACE_BY_ID,
  SET_PLACE,
  SET_PLACES,
  UPDATE_PLACE,
} from "constants/places";
import { networkAction } from "helpers/network/networkAction";

export const setPlaces = (places) => async (dispatch) =>
  dispatch({
    type: SET_PLACES,
    places,
  });

export const setPlace = (place) => async (dispatch) =>
  dispatch({
    type: SET_PLACE,
    place,
  });

export const getPlaces = () => async (dispatch) =>
  networkAction(dispatch, GET_PLACES, Api.getPlaces, []);

export const getPlaceById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_PLACE_BY_ID, Api.getPlaceById, [id]);

export const deletePlace = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_PLACE, Api.deletePlace, [id]);

export const createPlace = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_PLACE, Api.createPlace, [body]);

export const updatePlace = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_PLACE, Api.updatePlace, [id, body]);
