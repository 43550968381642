import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "alp";
const placeEvents = `${REACT_APP_API_PREFIX}/${service}/placeEvents`;

export function getPlaceEvents(filters) {
  const url = `${placeEvents}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getPlaceEventById(id) {
  const url = `${placeEvents}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updatePlaceEvent(id, body) {
  const url = `${placeEvents}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deletePlaceEvent(id) {
  const url = `${placeEvents}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createPlaceEvent(body) {
  const url = `${placeEvents}`;
  return {
    method: POST,
    url,
    body,
  };
}
