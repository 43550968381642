import {
  Button,
  Divider,
  Grow,
  List,
  ListItem,
  ListItemText,
  Popover,
  TextField,
} from "@material-ui/core";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import { createPromoCode, getPromoCodes } from "actions/promoCodes";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function PromoCodesPanel({ anchorEl, open, onClose }) {
  const [codes, setCodes] = React.useState("");
  const promoCodes = useSelector((s) => s.promoCodes);
  const classes = useStyles(styles);
  const dispatch = useDispatch();

  const codesDetected = codes.split("\n").filter((e) => e.length === 12);

  const addCodes = () => {
    const proms = [];
    setCodes("");
    for (const key in codesDetected) {
      if (Object.hasOwnProperty.call(codesDetected, key)) {
        const codeDetected = codesDetected[key];
        proms.push(
          dispatch(
            createPromoCode({
              code: codeDetected,
            })
          )
        );
      }
    }
    Promise.all(proms).then(() => dispatch(getPromoCodes()));
  };

  return (
    <Popover
      getContentAnchorEl={null}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
    >
      <div style={{ width: 320, maxHeight: 400, overflow: "scroll" }}>
        <div
          style={{
            position: "sticky",
            top: 0,
            background: "white",
            padding: 8,
            zIndex: 99,
          }}
        >
          <TextField
            variant="outlined"
            onChange={(e) => setCodes(e.target.value)}
            label="Enter new codes"
            multiline={true}
            fullWidth
            size="small"
            helperText={
              codes && codesDetected.length
                ? `${codesDetected.length} codes detected`
                : undefined
            }
            maxRows={5}
          />
          {codes && codesDetected.length ? (
            <Grow in>
              <Button
                fullWidth
                onClick={addCodes}
                variant="contained"
                size="small"
                style={{ background: "#2196f3", color: "white" }}
              >
                Add
              </Button>
            </Grow>
          ) : (
            []
          )}
          <Divider style={{ marginTop: 8 }}></Divider>
        </div>
        <List>
          {promoCodes.map((c) => (
            <ListItem button>
              <ListItemText button primary={c.code} secondary={"Expires in "} />
            </ListItem>
          ))}
        </List>
      </div>
    </Popover>
  );
}
