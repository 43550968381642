export default (theme) => ({
  container: {
    zIndex: 99,
    width: "100vw",
    height: "100vw",
    position: "absolute",
    display: "flex",
    backdropFilter: "blur(10px)",
    transition: "backdrop-filter .5s",
    justifyContent: "center",
  },
  dataContainer: {
    marginTop: 50,
    maxWidth: 600,
    width: "100%",
    position: "relative",
  },
  searchBar: {
    background: "white",
    padding: 16,
    borderRadius: 8,
    boxShadow: "0px 6px 10px rgba(0,0,0,0.2)",
  },
  searchResult: {
    marginTop: -6,
    paddingTop: 6,
    background: "rgba(255,255,255,0.8)",
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
});
