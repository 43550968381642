// import PropTypes from 'prop-types';
// material-ui
import { Badge, Fab, Grow, Tooltip } from "@material-ui/core";
import { CardGiftcard, Search } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import {
  createPlace,
  setDiscoverEnabled,
  setSearchOpen,
  setSelectedPlaceID,
  toggleDiscoverEnabled,
  toggleSearchOpen,
} from "actions";
import cursor from "assets/discover.png";
import { mapOptions } from "config/mapOptions";
import { parsePlace } from "helpers";
import Map from "hooks/Map";
import Marker from "hooks/Marker";
import PlaceDetails from "hooks/PlaceDetails";
import PlaceSearch from "hooks/PlaceSearch";
import PromoCodesPanel from "hooks/PromoCodesPanel";
import hotkeys from "hotkeys-js";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { store } from "store";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);
export default function App() {
  const [predictionService, setPredictionService] = React.useState();
  const [placeDetailService, setPlaceDetailService] = React.useState();
  const [geocoderService, setGeocoderService] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState();
  const [zoom, setZoom] = React.useState(12);
  const discoverEnabled = useSelector((s) => s.app.discoverEnabled);
  const classes = useStyles(styles);
  const [center, setCenter] = React.useState({
    lat: 34.2021084,
    lng: -118.5203226,
  });

  const places = useSelector((s) => s.places);
  const promoCodes = useSelector((s) => s.promoCodes);
  const selectedPlaceID = useSelector((s) => s.app.selectedPlaceID);
  const [map, setMap] = React.useState();
  const dispatch = useDispatch();

  React.useEffect(() => {
    hotkeys("ctrl+k, command+k", function () {
      dispatch(toggleSearchOpen());
    });

    hotkeys("esc", function () {
      dispatch(setSearchOpen(false));
    });

    hotkeys("*", function (e) {
      if (e.key === "Alt") {
        dispatch(toggleDiscoverEnabled());
      }
    });

    window.document.addEventListener("keyup", () => {
      dispatch(setDiscoverEnabled(false));
    });
  }, []);

  const onMarkerClick = (p) => (e) => {
    dispatch(setSelectedPlaceID(p.id));
  };

  const onPlaceAdd = (p) => {
    setCenter({ lat: parseFloat(p.latitude), lng: parseFloat(p.longitude) });
    dispatch(createPlace(p));
  };

  React.useEffect(() => {
    setPredictionService(
      new window.google.maps.places.AutocompleteService(null)
    );

    setGeocoderService(new window.google.maps.Geocoder());
    return () => {
      hotkeys.deleteScope("*");
      hotkeys.unbind("*");
    };
  }, []);

  // React.useEffect(() => {
  //   if (discoverEnabled) {
  //     map.setOptions({ draggableCursor: "" });
  //   }
  // }, discoverEnabled);

  React.useEffect(() => {
    if (map) {
      setPlaceDetailService(new window.google.maps.places.PlacesService(map));
    }
  }, [map]);

  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };

  function handleMapClicked(e) {
    if (e.latLng && discoverEnabled) {
      geocoderService.geocode({ latLng: e.latLng }, (results) => {
        if (results.length > 0) {
          const loc = results[0];
          placeDetailService.getDetails({ placeId: loc.place_id }, (d) => {
            const parsed = parsePlace(loc);
            const place = {
              phone: d.international_phone_number,
              website: d.website,
              rating: d.rating,
              photo: d.photos && d.photos.length && d.photos[0]?.getUrl(),
              title: d.name,
              googlePlaceID: loc.place_id,
              latitude: `${loc.geometry.location.lat()}`,
              longitude: `${loc.geometry.location.lng()}`,
              address: parsed.line1,
              city: parsed.city,
              state: parsed.state,
              country: parsed.country,
              zipcode: parsed.zipcode,
            };
            onPlaceAdd(place);
          });
        }
      });
    }
    dispatch(setSelectedPlaceID());
  }

  function getPercentage(campaign) {
    const percentage = campaign.redeemCount / campaign.promoQuantity;
    if (percentage === 0) return 0;
    if (percentage <= 0.25) return 25;
    if (percentage > 0.25 && percentage <= 0.5) return 50;
    if (percentage > 0.5 && percentage <= 0.75) return 75;
    if (percentage >= 0.75) return 100;

    return 0;
  }

  function getMarkerIcon(place) {
    if (!place.campaigns || place.campaigns.length === 0)
      return "https://storage.googleapis.com/tsk/pin/tsk_blank.png";

    const lastCampaign = place.campaigns[place.campaigns.length - 1];

    console.log(lastCampaign);

    if (
      !lastCampaign.contactedTime &&
      !lastCampaign.stickered &&
      !lastCampaign.redeemCount
    )
      return "https://storage.googleapis.com/tsk/pin/tsk_blank.png";
    if (lastCampaign.unsubscribed)
      return "https://storage.googleapis.com/tsk/pin/tsk_unsubscribed.png";
    if (lastCampaign.redeemCount) {
      const percentage = getPercentage(lastCampaign);
      return `https://storage.googleapis.com/tsk/pin/tsk${
        lastCampaign.stickered ? "_stickered" : ""
      }${lastCampaign.redeemCount ? `_active_${percentage}` : ""}.png`;
    } else {
      return `https://storage.googleapis.com/tsk/pin/tsk${
        lastCampaign.stickered ? "_stickered" : ""
      }${
        lastCampaign.contactedTime
          ? `_contacted_${lastCampaign.contactedTime}`
          : ""
      }.png`;
    }
  }

  console.log(places);

  const selectedPlace = selectedPlaceID
    ? places.find((place) => place.id === selectedPlaceID)
    : undefined;

  return (
    <div style={{ flex: 1, display: "flex" }}>
      <Map
        center={center}
        zoom={zoom}
        onIdle={onIdle}
        onClick={handleMapClicked}
        mapSetter={setMap}
        options={{
          ...mapOptions,
          draggableCursor: discoverEnabled
            ? `url(${cursor}), crosshair`
            : undefined,
        }}
        style={{ flexGrow: "1", height: "100%" }}
      >
        {places.map((p) => (
          <Marker
            title={"Hello World"}
            icon={getMarkerIcon(p)}
            key={p.placeId}
            opacity={!selectedPlaceID || selectedPlaceID === p.id ? 1 : 0.4}
            onClick={onMarkerClick(p)}
            position={{
              lat: parseFloat(p.latitude),
              lng: parseFloat(p.longitude),
            }}
          />
        ))}
        <PlaceSearch
          predictionService={predictionService}
          placeDetailService={placeDetailService}
          geocoderService={geocoderService}
          onPlaceAdd={(p) => {
            const alreadyExist = places.find(
              (e) => e.googlePlaceID === p.googlePlaceID
            );
            if (!alreadyExist) {
              onPlaceAdd(p);
            } else {
              store.dispatch(setSelectedPlaceID(alreadyExist.id));
            }
            setZoom(18);
            setCenter({
              lat: parseFloat(p.latitude),
              lng: parseFloat(p.longitude),
            });
          }}
        />
      </Map>
      {selectedPlace ? (
        <Grow in>
          <div className={classes.markerDrawer}>
            <PlaceDetails key={selectedPlace.id} place={selectedPlace} />
          </div>
        </Grow>
      ) : (
        []
      )}
      <div
        style={{
          position: "absolute",
          top: 10,
          right: 110,
        }}
      >
        <Badge
          overlap="circular"
          badgeContent={
            <span style={{ color: "white" }}>{promoCodes.length}</span>
          }
          color="error"
        >
          <Fab
            onClick={(e) => setAnchorEl(e.currentTarget)}
            size="small"
            style={{
              background: "white",
            }}
          >
            <CardGiftcard />
          </Fab>
        </Badge>
        <PromoCodesPanel
          onClose={() => setAnchorEl()}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
        />
      </div>
      <Tooltip title="Search Place (CMD + K)">
        <Fab
          onClick={() => dispatch(setSearchOpen(true))}
          size="small"
          style={{
            position: "absolute",
            top: 10,
            right: 60,
            background: "white",
          }}
        >
          <Search />
        </Fab>
      </Tooltip>
    </div>
  );
}
