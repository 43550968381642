import { getPlaceById, getPlaces, setSelectedPlaceID } from "actions";
import { CREATE_CAMPAIGN, DELETE_CAMPAIGN } from "constants/campaigns";
import { CREATE_PLACE_EVENT, DELETE_PLACE_EVENT } from "constants/placeEvents";
import { CREATE_PLACE, DELETE_PLACE } from "constants/places";

export function appMiddleware(store) {
  return (next) => (action) => {
    const state = store.getState();

    if (action.type === `${DELETE_PLACE}_LOADING`) {
      store.dispatch(setSelectedPlaceID());
    }

    if (action.type === `${DELETE_PLACE}_SUCCESS`) {
      store.dispatch(getPlaces());
    }

    if (action.type === `${CREATE_PLACE}_SUCCESS`) {
      store.dispatch(setSelectedPlaceID(action.payload.id));
      store.dispatch(getPlaces());
    }

    if (action.type === `${CREATE_CAMPAIGN}_SUCCESS`) {
      store.dispatch(getPlaceById(action.payload.placeID));
    }

    if (action.type === `${DELETE_CAMPAIGN}_SUCCESS`) {
      store.dispatch(getPlaceById(state.app.selectedPlaceID));
    }

    if (action.type === `${CREATE_PLACE_EVENT}_SUCCESS`) {
      store.dispatch(getPlaceById(action.payload.placeID));
    }

    if (action.type === `${DELETE_PLACE_EVENT}_SUCCESS`) {
      store.dispatch(getPlaceById(state.app.selectedPlaceID));
    }

    return next(action);
  };
}
