import { REACT_APP_API_PREFIX } from "config/env";
import { DELETE, GET, POST, PUT } from "constants/methods";

const service = "alp";
const places = `${REACT_APP_API_PREFIX}/${service}/places`;

export function getPlaces(filters) {
  const url = `${places}${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getPlaceById(id) {
  const url = `${places}/${id}`;
  return {
    method: GET,
    url,
  };
}

export function updatePlace(id, body) {
  const url = `${places}/${id}`;
  return {
    method: PUT,
    url,
    body,
  };
}

export function deletePlace(id) {
  const url = `${places}/${id}`;
  return {
    method: DELETE,
    url,
  };
}

export function createPlace(body) {
  const url = `${places}`;
  return {
    method: POST,
    url,
    body,
  };
}
