import { appMiddleware } from "appMiddleware";
import { applyMiddleware, createStore } from "redux";
import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import rootReducer from "./reducers/rootReducer";

// const persistedState = localStorage.getItem("tskstate")
//   ? JSON.parse(localStorage.getItem("tskstate"))
//   : {};

export const store = createStore(
  rootReducer,
  applyMiddleware(thunk, createLogger(), appMiddleware)
);
