import { networkAction } from 'helpers/network/networkAction';

import {
  GET_PLACE_EVENTS,
  GET_PLACE_EVENT_BY_ID,
  DELETE_PLACE_EVENT,
  UPDATE_PLACE_EVENT,
  CREATE_PLACE_EVENT,
} from 'constants/placeEvents';

import * as Api from 'api';

export const getPlaceEvents = () => async dispatch => networkAction(
  dispatch,
  GET_PLACE_EVENTS,
  Api.getPlaceEvents,
  []
);

export const getPlaceEventById = (id) => async dispatch => networkAction(
  dispatch,
  GET_PLACE_EVENT_BY_ID,
  Api.getPlaceEventById,
  [id]
);

export const deletePlaceEvent = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_PLACE_EVENT,
  Api.deletePlaceEvent,
  [id]
);

export const createPlaceEvent = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_PLACE_EVENT,
  Api.createPlaceEvent,
  [body]
);

export const updatePlaceEvent = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_PLACE_EVENT,
  Api.updatePlaceEvent,
  [id, body]
);
