import { networkAction } from 'helpers/network/networkAction';

import {
  GET_CAMPAIGNS,
  GET_CAMPAIGN_BY_ID,
  DELETE_CAMPAIGN,
  UPDATE_CAMPAIGN,
  CREATE_CAMPAIGN,
} from 'constants/campaigns';

import * as Api from 'api';

export const getCampaigns = () => async dispatch => networkAction(
  dispatch,
  GET_CAMPAIGNS,
  Api.getCampaigns,
  []
);

export const getCampaignById = (id) => async dispatch => networkAction(
  dispatch,
  GET_CAMPAIGN_BY_ID,
  Api.getCampaignById,
  [id]
);

export const deleteCampaign = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_CAMPAIGN,
  Api.deleteCampaign,
  [id]
);

export const createCampaign = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_CAMPAIGN,
  Api.createCampaign,
  [body]
);

export const updateCampaign = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_CAMPAIGN,
  Api.updateCampaign,
  [id, body]
);
