import {
  SET_DISCOVER_ENABLED,
  SET_SEARCH_OPEN,
  SET_SELECTED_PLACE_ID,
  TOGGLE_DISCOVER_ENABLED,
  TOGGLE_SEARCH_OPEN,
} from "constants/app";

export default (state = {}, action) => {
  switch (action.type) {
    case SET_SEARCH_OPEN:
      return { ...state, searchOpen: action.searchOpen };
    case TOGGLE_SEARCH_OPEN:
      return { ...state, searchOpen: !state.searchOpen };
    case SET_DISCOVER_ENABLED:
      return { ...state, discoverEnabled: action.discoverEnabled };
    case TOGGLE_DISCOVER_ENABLED:
      return { ...state, discoverEnabled: !state.discoverEnabled };
    case SET_SELECTED_PLACE_ID:
      return { ...state, selectedPlaceID: action.selectedPlaceID };
    default:
      return state;
  }
};
