// material-ui
import { Wrapper } from "@googlemaps/react-wrapper";
import { getPlaces, getPromoCodes } from "actions";
// components
import App from "components/App";
import React from "react";
import { useDispatch } from "react-redux";

export default function Root() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getPlaces());
    dispatch(getPromoCodes());
  }, []);
  return (
    <Wrapper
      apiKey={"AIzaSyD53MEFFilyOcCMMzD6sfat1Gdu01UiWaA"}
      libraries={["places"]}
    >
      <App />
    </Wrapper>
  );
}
