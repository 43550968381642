import { networkAction } from 'helpers/network/networkAction';

import {
  GET_PROMO_CODES,
  GET_PROMO_CODE_BY_ID,
  DELETE_PROMO_CODE,
  UPDATE_PROMO_CODE,
  CREATE_PROMO_CODE,
} from 'constants/promoCodes';

import * as Api from 'api';

export const getPromoCodes = () => async dispatch => networkAction(
  dispatch,
  GET_PROMO_CODES,
  Api.getPromoCodes,
  []
);

export const getPromoCodeById = (id) => async dispatch => networkAction(
  dispatch,
  GET_PROMO_CODE_BY_ID,
  Api.getPromoCodeById,
  [id]
);

export const deletePromoCode = (id) => async dispatch => networkAction(
  dispatch,
  DELETE_PROMO_CODE,
  Api.deletePromoCode,
  [id]
);

export const createPromoCode = (body) => async dispatch => networkAction(
  dispatch,
  CREATE_PROMO_CODE,
  Api.createPromoCode,
  [body]
);

export const updatePromoCode = (id, body) => async dispatch => networkAction(
  dispatch,
  UPDATE_PROMO_CODE,
  Api.updatePromoCode,
  [id, body]
);
