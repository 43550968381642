// import PropTypes from 'prop-types';
// material-ui
import { List, ListItem, ListItemText, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { setSearchOpen } from "actions";
import { parsePlace } from "helpers";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function PlaceSearch({
  onPlaceAdd,
  map,
  predictionService,
  placeDetailService,
  geocoderService,
}) {
  const [searchText, setSearchText] = React.useState("");
  const [predictions, setPredictions] = React.useState([]);
  const classes = useStyles(styles);
  const dispatch = useDispatch();
  const searchOpen = useSelector((state) => state.app.searchOpen);

  const handleChange = (e) => {
    setSearchText(e.target.value);
    predictionService.getPlacePredictions(
      {
        input: e.target.value,
        componentRestrictions: { country: "us" },
        // bounds,
      },
      (googleSuggestions) => setPredictions(googleSuggestions ?? [])
    );
  };

  const handlePlaceSelect = (p) => () => {
    dispatch(setSearchOpen(false));

    placeDetailService.getDetails({ placeId: p.place_id }, (d) =>
      geocoderService.geocode({ address: p.description }, (results) => {
        if (results.length > 0) {
          const loc = results[0];
          const parsed = parsePlace(loc);

          const place = {
            phone: d.international_phone_number,
            website: d.website,
            rating: d.rating,
            photo: d.photos && d.photos.length && d.photos[0]?.getUrl(),
            title: d.name,
            googlePlaceID: loc.place_id,
            latitude: `${loc.geometry.location.lat()}`,
            longitude: `${loc.geometry.location.lng()}`,
            address: parsed.line1,
            city: parsed.city,
            state: parsed.state,
            country: parsed.country,
            zipcode: parsed.zipcode,
          };
          onPlaceAdd(place);
          setPredictions([]);
          setSearchText("");
        }
      })
    );
  };

  if (!searchOpen) return <div />;

  return (
    <div className={classes.container}>
      <div className={classes.dataContainer}>
        <div className={classes.searchBar}>
          <TextField
            value={searchText}
            onChange={handleChange}
            fullWidth
            label="Search a Place..."
            autoFocus
            placeholder="Tennis Facility Calabasas"
          />
        </div>
        <div className={classes.searchResult}>
          <List>
            {predictions.map((p) => (
              <ListItem button onClick={handlePlaceSelect(p)}>
                <ListItemText primary={p.description} />
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    </div>
  );
}
