import {
  SET_DISCOVER_ENABLED,
  SET_SEARCH_OPEN,
  SET_SELECTED_PLACE_ID,
  TOGGLE_DISCOVER_ENABLED,
  TOGGLE_SEARCH_OPEN,
} from "constants/app";

export const setSearchOpen = (searchOpen) => async (dispatch) =>
  dispatch({
    type: SET_SEARCH_OPEN,
    searchOpen,
  });

export const toggleSearchOpen = () => async (dispatch) =>
  dispatch({
    type: TOGGLE_SEARCH_OPEN,
  });

export const setDiscoverEnabled = (discoverEnabled) => async (dispatch) =>
  dispatch({
    type: SET_DISCOVER_ENABLED,
    discoverEnabled,
  });

export const toggleDiscoverEnabled = () => async (dispatch) =>
  dispatch({
    type: TOGGLE_DISCOVER_ENABLED,
  });

export const setSelectedPlaceID = (selectedPlaceID) => async (dispatch) =>
  dispatch({
    type: SET_SELECTED_PLACE_ID,
    selectedPlaceID,
  });
